import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography } from '@material-ui/core';
import { WizardFooter, WizardHeader } from './NgoNcSpaceReqStoreListComp';
import { ChevronDown, Calendar } from '@livingdesign/icons';
import React, { useContext, useEffect, useState } from 'react';
import './NcNgoCreateEventDetails.css'
import LDDivider from '../../commonComponents/uiComponents/LDDivider';
import DataTable from 'react-data-table-component';
import LDButton from '../../commonComponents/uiComponents/LDButton';
import { Card, Checkbox, Modal, Select } from '@walmart-web/livingdesign-components';
import { useMutation, useQuery } from 'react-query';
import SpaceDonationService, { Service } from 'src/spaceDonationService';
import moment from 'moment';
import Loader from '../../commonComponents/appComponents/loader/loader';
import { useNavigate, useLocation } from 'react-router';
import ReserveDateTime from './ReserveDateTime';
import UserContext from '../../store/user-context';
import LDModal from '../../commonComponents/uiComponents/LDModal';
import { contextPath } from 'src/constants';

interface NcNgoCreateEventDetailsProp {
  history?: any[]
}

interface NcIDProps {
  pathname: string;
  state: {
    ncid: any
    orgid: any
  };
}

const service: Service = SpaceDonationService;

export const handleFileDownload = (data: any, setSecondChecked: any) => {
  setSecondChecked(true);
  window.open(data);
}

const NcNgoCreateEventDetails: React.FC<NcNgoCreateEventDetailsProp> = () => {
  const history = useNavigate();
  const { state } = useLocation() as NcIDProps;
  const ncid = state?.ncid
  const orgid = state?.orgid

  if (ncid === undefined || orgid === undefined) {
    history(`${contextPath}/space-donation/create/`)
  }
  const {
    data: eventDetails,
    isLoading } =
    useQuery(['getNcDetailsWithSelectedStoreByOrgForNC', { ncid, orgid }],
      service.getNcDetailsWithSelectedStoreByOrgForNC, { refetchOnWindowFocus: false });
  const [disableStatus, setDisableStatus] = useState(true);
  const [accordion, setAccordion] = useState('1')
  const [updatedContactDetail, setUpdatedContactDetail] = useState({});
  const [loader, setLoader] = useState(false)
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const mutation = useMutation(service.submitRequestNC);
  const handleNextRedirect = () => {
    setLoader(true);
    const payload = {
      ncId: ncid,
      spaceOrganizationId: orgid,
      ...updatedContactDetail,
      spaceOrganizationName: eventDetails?.spaceOrganizationResponse?.orgName,
      isUpdate: true,
      status: 'DRAFT'
    }
    mutation.mutate(payload, {
      onSuccess: () => {
        setLoader(false)
        history(`${contextPath}/space-donation/create/submitted-nc`, { state: { orgid } })
      }, onError: (e: any) => {
        setIsError(true)
        setLoader(false)
        setErrorMessage(e.response.data.errorMessage)
      }
    });
  }

  const handlePreviousRedirect = () => {
    history(`${contextPath}/space-donation/create/store-details`, { state: { ncid, orgid } })
  }

  const headerSection = {
    title: 'Request Space',
    progressTrackerItem: ['Select Store', 'Date and Time', 'Summary'],
    activeIndex: 2,
    handlePreviousRedirect
  }
  const footerSection = {
    isContinueDisabled: disableStatus,
    previousButtonText: 'Previous',
    nextButtonText: 'Submit Request',
    handleNextRedirect,
    handlePreviousRedirect
  }
  const storeDateTimeSelected = eventDetails?.spaceStoreAndNationalCampaignEventResponseDTOList?.filter((ele: any) => {
    return ele.proposedEventDates.length > 0
  })
  const storeListLength = storeDateTimeSelected?.length
  const contactMutation = useMutation((data: any) =>
    service.getAllContactDetails(data)
  );
  const contactPayload: any = {
    orgId: orgid,
    ncId: ncid,
  };

  useEffect(() => {
    contactMutation.mutate(contactPayload, {
      onSuccess: (data) => {
        setUpdatedContactDetail({
          contactFirstName: data[0]?.eventContactDetails?.contactFirstName,
          contactLastName: data[0]?.eventContactDetails?.contactLastName,
          spaceOrganizationEmail: data[0].eventContactDetails?.spaceOrganizationEmail,
          spaceOrganizationPhone: data[0].eventContactDetails?.spaceOrganizationPhone,
        }
        )
      }
    });
  }, [])


  const campaignName = eventDetails?.spaceNationalCampaign?.title ?
    eventDetails?.spaceNationalCampaign?.title : 'Fetching name details...'
  return (
    <>
      <LDModal isOpen={isError} size='small'
        onClose={() => setIsError(false)}
        title={<div className='submit-error-message'>Error</div>}>
        {errorMessage}
      </LDModal>
      {isLoading && <Loader />}
      {loader && <Loader />}
      {contactMutation?.isLoading && <Loader />}
      <WizardHeader {...headerSection} />
      <div className='ngo-nc-accordion-list'>
        <LDDivider className='ngo-nc-divider-mobile' />
        <div className='ngo-nc-accordion-item'>
          <Accordion expanded={accordion === '1' && !isLoading} onClick={() => setAccordion('1')} data-testid='first-accordion'>
            <AccordionSummary
              expandIcon={<ChevronDown />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography><span className='ngo-nc-accordion-title'>
                {campaignName}</span>
              </Typography>
            </AccordionSummary>
            <LDDivider className='ngo-nc-divider-mobile' />
            <AccordionDetails>
              <DisplayNcDetails details={eventDetails?.spaceNationalCampaign} />
            </AccordionDetails>
          </Accordion>
        </div>
        <LDDivider className='ngo-nc-divider-mobile' />
        <div className='ngo-nc-accordion-item'>
          <Accordion expanded={accordion === '2' && !isLoading} onClick={() => setAccordion('2')} data-testid='second-accordion'>
            <AccordionSummary
              expandIcon={<ChevronDown />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography><span className='ngo-nc-accordion-title'>
                Store Selection ({storeListLength ? storeListLength : '...'})</span></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DisplayStoreList
                storeList={storeDateTimeSelected}
                startDate={eventDetails?.spaceNationalCampaign?.eventStartDateString}
                endDate={eventDetails?.spaceNationalCampaign?.eventEndDateString}
                eventSlots={eventDetails?.spaceNationalCampaign?.eventSlots}
                maxAllowedDays={eventDetails?.spaceNationalCampaign?.maxAllowedDays}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <LDDivider className='ngo-nc-divider-mobile' />
        <div className='ngo-nc-accordion-item'>
          <Accordion expanded={accordion === '3' && !isLoading} onClick={() => setAccordion('3')} data-testid='third-accordion'>
            <AccordionSummary
              expandIcon={<ChevronDown />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography><span className='ngo-nc-accordion-title'>Contact information</span></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ContactDetails spaceOrganizationResponse={contactMutation?.data}
                isContactLoading={contactMutation.isLoading}
                setUpdatedContactDetail={setUpdatedContactDetail} />
            </AccordionDetails>
          </Accordion>
        </div>
        <LDDivider className='ngo-nc-divider-mobile' />
        <AgreementCard eventDetails={eventDetails} setDisableStatus={setDisableStatus} />
        <WizardFooter {...footerSection} />
      </div>
    </>
  )
}

interface DisplayNcDetailsProp {
  details?: any
}

export const DisplayNcDetails: React.FC<DisplayNcDetailsProp> = ({ details }) => {
  const format = 'ddd MMM DD, YYYY';
  const startDate = moment(details?.eventStartDateString).format(format);
  const endDate = moment(details?.eventEndDateString).format(format);
  const createdDate = moment(details?.audit?.createdDtm).format(format);

  const getTimeSlots = () => {
    return <div>{details?.eventSlots?.map((item: any) => <div>{`${item.slotName} (${item.label})`}</div>)}</div>;
  }

  return (
    <div className='ngo-nc-display-details'>
      <div className='ngo-nc-display-info'>
        <div className='ngo-nc-info-left' >
          <div className='event-details-display-first-text'>
            <span className='event-details-display-text-label'>Type of event:</span>
            <span className='event-details-display-value'>{details?.donationType === 'SPACE_DONATION_NC' ? 'National Campaign' : 'Local Campaign'}</span>
          </div><LDDivider className='ngo-nc-divider' />
          <div className='event-details-display-text'>
            <span className='event-details-display-text-label'>Created date:</span>
            <span className='event-details-display-value'>{createdDate}</span>
          </div> <LDDivider className='ngo-nc-divider' />
          <div className='event-details-display-text'>
            <span className='event-details-display-text-label'>Campaign proposal:</span>
            <span className='event-details-display-value event-campaign-dates'>
              {startDate} - {endDate}
            </span>
          </div> <LDDivider className='ngo-nc-divider' />
          <div className='event-details-display-text'>
            <span className='event-details-display-text-label'>Time:</span>
            <span className='event-details-display-value'>{getTimeSlots()}</span>
          </div><LDDivider className='ngo-nc-divider' />
        </div>
        <div className='ngo-nc-info-right'>
          <div className='event-details-display-first-text'>
            <span className='event-details-display-text-label'>Purpose:</span>
            <span className='event-details-display-value'>{details?.purpose}</span>
          </div> <LDDivider className='ngo-nc-divider' />
          <div className='event-details-display-text'>
            <span className='event-details-display-text-label'>Focus area:</span>
            <span className='event-details-display-value'>{details?.focusArea}</span>
          </div><LDDivider className='ngo-nc-divider' />
          <div className='event-details-display-text'>
            <span className='event-details-display-text-label'>Event Space:</span>
            <span className='event-details-display-value'>
              {details?.spaceLocationDetails?.location} | {details?.spaceLocationDetails?.name}
            </span>
          </div>
        </div>
      </div>
      <div className='event-ngo-details-display-description-wrap'>
        <span className='event-ngo-details-display-description'>Description:</span>
        <span className='event-ngo-details-display-description-value'>{details?.description} </span>
      </div>
    </div>
  )
}

interface DisplayStoreListProp {
  storeList?: any[];
  startDate?: any;
  endDate?: any;
  eventSlots?: any;
  maxAllowedDays?: any;
}

interface ContactDetailsProp {
  spaceOrganizationResponse?: any,
  isContactLoading?: boolean,
  setUpdatedContactDetail: any,
}
export const DisplayStoreList: React.FC<DisplayStoreListProp> = ({ storeList, startDate, endDate, eventSlots, maxAllowedDays }) => {
  const [showReserveDateTimeModal, setShowReserveDateTimeModal] = React.useState(false);
  const [timeSelectedStores, setTimeSelectedStores] = useState<any[]>([]);
  const customStyle = {
    rows: {
      style: {
        flexWrap: 'wrap'
      }
    }
  }
  const dateTimeColumn = {
    name: 'View date and time',
    selector: (row: any) => row?.range,
    cell: (row: any) => <Calendar data-testid='cal-click'
      onClick={() => { setTimeSelectedStores([row]); setShowReserveDateTimeModal(true) }} />,
    style: { justifyContent: 'flex-end', cursor: 'pointer' },
    sortable: false,
  }
  return (
    <>
      <div className='ngo-nc-data-table'>
        <DataTable
          columns={[...getColumns(), dateTimeColumn]}
          data={storeList ? storeList : []}
          pagination={true}
          customStyles={customStyle}
        />
      </div>
      <ReserveDateTime
        isOpen={showReserveDateTimeModal}
        onClose={() => {
          setShowReserveDateTimeModal(false);
          setTimeSelectedStores([]);
        }}
        minDate={new Date(startDate)}
        maxDate={new Date(endDate)}
        eventSlots={eventSlots || []}
        selectedStores={[]}
        timeSelectedStores={timeSelectedStores}
        onConfirmSelection={() => {/* */ }}
        disabled={true}
        maxAllowedDays={maxAllowedDays}
      />
    </>
  )
}

export const ContactDetails: React.FC<ContactDetailsProp> = ({ spaceOrganizationResponse, isContactLoading, setUpdatedContactDetail }) => {
  const [modal, setModalOpen] = useState(false);
  const [index, setIndex] = React.useState(0);
  const [email, setEmail] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [currentName, setCurrentName] = useState('');
  let contactDetails: any;
  const contactDetailsList = spaceOrganizationResponse ? spaceOrganizationResponse : '';
  contactDetails = contactDetailsList?.filter?.((item: any) => item?.nationalCampaignContact);
  const contact = contactDetails ? contactDetails[0] : '';
  const [modalEmail, setModalEmail] = useState();
  const [modalPhoneNo, setModalPhoneNo] = useState();
  const [modalName, setModalName] = useState('');
  const [modalIndex, setModalIndex] = useState(0);
  const userContext: any = useContext(UserContext);
  const orgName = userContext?.organization?.orgName;
  useEffect(() => {
    setEmail(contact ? contact.eventContactDetails?.spaceOrganizationEmail : '');
    setPhoneNo(contact ? contact.eventContactDetails?.spaceOrganizationPhone : '');
    setCurrentName(contact ? contact.eventContactDetails?.contactFirstName + ' ' + contact.eventContactDetails?.contactLastName : '');
  }, [isContactLoading]);

  useEffect(() => {
    if (contactDetailsList) {
      setUpdatedContactDetail({
        contactFirstName: contactDetailsList[modalIndex]?.eventContactDetails?.contactFirstName,
        contactLastName: contactDetailsList[modalIndex]?.eventContactDetails?.contactLastName,
        spaceOrganizationEmail: contactDetailsList[modalIndex].eventContactDetails?.spaceOrganizationEmail,
        spaceOrganizationPhone: contactDetailsList[modalIndex].eventContactDetails?.spaceOrganizationPhone,
      });
    }
  }, []);

  const handleContactChange = (e: any) => {
    setModalIndex(e.target.value);
    setModalEmail(
      contactDetailsList && contactDetailsList.length > 0
        ? contactDetailsList[e.target.value]?.eventContactDetails?.spaceOrganizationEmail
        : ''
    );
    setModalPhoneNo(
      contactDetailsList && contactDetailsList.length > 0
        ? contactDetailsList[e.target.value]?.eventContactDetails?.spaceOrganizationPhone
        : ''
    );
    setModalName(
      contactDetailsList && contactDetailsList.length > 0
        ? contactDetailsList[e.target.value]?.eventContactDetails?.contactFirstName +
        ' ' +
        contactDetailsList[e.target.value]?.eventContactDetails?.contactLastName
        : ''
    );
  };

  const handleContactUpdate = () => {
    setIndex(modalIndex);
    setEmail(modalEmail);
    setPhoneNo(modalPhoneNo);
    setCurrentName(modalName);
    setModalOpen(false);
    setUpdatedContactDetail({
      contactFirstName: contactDetailsList[modalIndex]?.eventContactDetails?.contactFirstName,
      contactLastName: contactDetailsList[modalIndex]?.eventContactDetails?.contactLastName,
      spaceOrganizationEmail: modalEmail,
      spaceOrganizationPhone: modalPhoneNo,
    });
  };

  const openModal = () => {
    setModalIndex(index);
    setModalEmail(email);
    setModalPhoneNo(phoneNo);
    setModalName(currentName);
    setModalOpen(true);
  };
  return (
    <>
      <Modal
        isOpen={modal}
        onClose={() => setModalOpen(false)}
        size="small"
        title="Edit Contact"
      >
        <div className="ngo-nc-edit-contact-modal">
          <div className="ngo-nc-edit-contact-details">
            <span className="ngo-nc-edit-contact-label">Full Name</span>
            <Select label={undefined} onChange={handleContactChange}>
              <option value={index}>{modalName}</option>
              {contactDetailsList?.map?.(
                (item: any, contactIndex: any) =>
                  Number(modalIndex) !== contactIndex && (
                    <option value={contactIndex} key={contactIndex}>
                      {item?.eventContactDetails?.contactFirstName} {item?.eventContactDetails?.contactLastName}
                    </option>
                  )
              )}
            </Select>
          </div>
          <div className="ngo-nc-edit-contact-details">
            <span className="ngo-nc-edit-contact-label">Email address</span>
            <span>{modalEmail}</span>
          </div>
          <div className="ngo-nc-edit-contact-details">
            <span className="ngo-nc-edit-contact-label">Phone number</span>
            <span>{modalPhoneNo}</span>
          </div>
          <div className="ngo-nc-edit-contact-details">
            <span className="ngo-nc-edit-contact-label">Organization</span>
            <span>{orgName}</span>
          </div>
        </div>
        <LDDivider />
        <div className="ngo-nc-modal-edit-contact--buttons">
          <div className="ngo-nc-cancel-edit-contact-button">
            <LDButton
              data-testid="close-modal"
              onClick={() => setModalOpen(false)}
              variant="tertiary"
            >
              Cancel
            </LDButton>
          </div>
          <div>
            <LDButton
              variant="primary"
              data-testid="update-contact"
              onClick={handleContactUpdate}
            >
              Update
            </LDButton>
          </div>
        </div>
      </Modal>
      <div className="ngo-nc-display-details">
        <div className="ngo-nc-display-info">
          <div className="ngo-nc-info-left">
            <div className="event-details-display-first-text">
              <span className="event-details-display-text-label">
                Full name:
              </span>
              <span>{currentName}</span>
            </div>
            <LDDivider className="ngo-nc-divider" />
            <div className="event-details-display-text">
              <span className="event-details-display-text-label">
                Phone number:
              </span>
              <span>{phoneNo}</span>
            </div>{" "}
            <LDDivider className="ngo-nc-divider" />
          </div>
          <div className="ngo-nc-info-right">
            <div className="event-details-display-first-text">
              <span className="event-details-display-text-label">
                Email address:
              </span>
              <span className="event-details-display-value">{email}</span>
            </div>{" "}
            <LDDivider className="ngo-nc-divider" />
            <div className="event-details-display-text">
              <span className="event-details-display-text-label">
                Organization:
              </span>
              <span className="event-details-display-value">{orgName}</span>
            </div>
            <LDDivider className="ngo-nc-divider" />
          </div>
        </div>
        <LDDivider className="ngo-nc-divider-last" />
        <div className="ngo-nc-edit-contact">
          <LDButton
            variant="secondary"
            data-testid="open-modal"
            onClick={() => openModal()}
          >
            Edit Contact
          </LDButton>
        </div>
      </div>
    </>
  );
}
interface AgreementCardProp {
  eventDetails: any;
  setDisableStatus: any;
}
export const AgreementCard: React.FC<AgreementCardProp> = ({ eventDetails, setDisableStatus }) => {

  const [secondChecked, setSecondChecked] = useState(false);

  const secondCheckboxSet = () => {
    if (secondChecked) {
      setSecondChecked(!secondChecked);
    }
  }
  const downloadMutation = useMutation((data: any) => service.downloadFileUrlMutation(data));

  const getFileDownloadUrl = (e: any) => {
    e.preventDefault();
    const payload = {
      fileId: eventDetails?.spaceNationalCampaign?.agreementFile?.fileId,
      container: 'space-donation',
      directory: eventDetails?.spaceNationalCampaign?.agreementFile?.container
    }
    downloadMutation.mutate(payload, {
      onSuccess: (data) => handleFileDownload(data, setSecondChecked)
    });
  }
  const checkboxlabel2 = eventDetails?.spaceNationalCampaign?.agreementLink ?
    <span>I agree to &nbsp;
      <a href={eventDetails?.spaceNationalCampaign?.agreementLink} data-testid='nc-second-label'
        onClick={() => setSecondChecked(true)} target='_blank'>
        <u>
          Terms of Use and Notice of Solicitation and Distribution of Literature Rules - National Campaign
        </u>
      </a>
    </span>
    :
    <span>I agree to &nbsp;
      <a href='#' data-testid='nc-second-label'
        onClick={(e) => getFileDownloadUrl(e)}>
        <u>
          Terms of Use and Notice of Solicitation and Distribution of Literature Rules - National Campaign
        </u>
      </a>
    </span>
  useEffect(() => {
    setDisableStatus(!(secondChecked));
  }, [secondChecked])
  return <>
    {downloadMutation.isLoading && <Loader />}
    <Card size='large' className='event-detail-info-card'>
      <div className='event-details-information-header-section'>
        <span className='event-details-information-header-title'>
          Accept user agreements
        </span>
      </div>
      <div className='ngo-nc-user-aggrement'>
        <span>We want to create a charitable and safe experience for our communities.
          Please agree to the following policies to complete your request.</span>
        <div className='ngo-nc-checkboxes'>
          <Checkbox checked={secondChecked}
            data-testid='second-Checkbox'
            label={checkboxlabel2}
            onChange={secondCheckboxSet} />
        </div>
      </div>
    </Card>
  </>
}

export default NcNgoCreateEventDetails;

export const getColumns = () => [
  {
    name: 'Store #',
    selector: (row: any) => row?.storeNbr,
    sortable: true,
  },
  {
    name: 'Store format',
    selector: (row: any) => row?.storeFormat,
    sortable: true,
    cell: (row: any) => <Tooltip title={row?.storeFormat} placement='right'>
      <span className='ngo-nc-ed-address-span-tooltip'>{row?.storeFormat}</span></Tooltip>,
  },
  {
    name: 'Address',
    selector: (row: any) => row?.addressLine1,
    sortable: true,
    cell: (row: any) => <Tooltip title={row?.addressLine1} placement='right'>
      <span className='ngo-nc-ed-address-span-tooltip'>{row?.addressLine1}</span></Tooltip>,
  },
  {
    name: 'City',
    selector: (row: any) => row?.city,
    sortable: true,
  },
  {
    name: 'State',
    selector: (row: any) => row?.state,
    sortable: true,
  },
  {
    name: 'ZIP',
    selector: (row: any) => row?.postalCode,
    sortable: true,
  }
]
